import React from 'react';
// import sections
import AdminPanel from '../components/sections/AdminPanel';

const Home = () => {

  return (
    <>
      <AdminPanel className="illustration-section-01" />
    </>
  );
}

export default Home;